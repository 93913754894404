import IStockInternationalMovementFilters from '@/types/IStockInternationalMovementFilters';
import StockInternationalMovementRepository from '@/repositories/StockInternationalMovementRepository';
import IStockInternationalMovement from '@/types/IStockInternationalMovement';
import StockInternationalMovementFactory from '@/factories/StockInternationalMovementFactory';
import FileService from '@/services/FileService';

export default class StockInternationalMovementService {
    static async getAll(filters: IStockInternationalMovementFilters)
        : Promise<{ stockInternationalMovements: IStockInternationalMovement[], totalElements: number }> {
        const {data} = await StockInternationalMovementRepository.getAll(filters);

        return {
            stockInternationalMovements: data.content.map((item: any) => StockInternationalMovementFactory.toStockInternationalMovement(item)),
            totalElements: data.totalElements,
        };
    }

    static async downloadFile(filters: IStockInternationalMovementFilters) {
        const {data} = await StockInternationalMovementRepository.download(filters);

        const file = new FileService(data);
        file.downloadFile('Stock-International-Movements');
    }

    static async getFilters(): Promise<{ arrivalCountryList: string[], departureCountryList: string[], skuList: string[] }> {
        const {data} = await StockInternationalMovementRepository.getFilters();

        return {
            arrivalCountryList: data.arrivalCountryList,
            departureCountryList: data.departureCountryList,
            skuList: data.skuList
        };
    }
}
