import IStockInternationalMovement from '@/types/IStockInternationalMovement';

export default class StockInternationalMovementFactory {
  static toStockInternationalMovement(data: any): IStockInternationalMovement {
    return {
      arrivalDate: data?.arrivalDate?.replace('T', ' ').substring(0, 10),
      departDate: data?.departDate?.replace('T', ' ').substring(0, 10),
      arrivalCountry: data.arrivalCountry,
      departureCountry: data.departureCountry,
      quantity: data.quantity,
      sku: data.sku,
      asin: data.asin,
      name: data.name,
      url: data.url,
    };
  }
}
