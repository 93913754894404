

















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import {IDataTablePagination} from '@/types/IPagination';
import IStockInternationalMovement from '@/types/IStockInternationalMovement';
import IStockInternationalMovementFilters from '@/types/IStockInternationalMovementFilters';

@Component({name: 'StockMovementTable',
  components: {}
})
export default class StockInternationalMovementTable extends Vue {
  @Prop()
  data!: IStockInternationalMovement[];

  @Prop()
  params!: IStockInternationalMovementFilters;

  @Prop()
  loading!: boolean;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_DEPART_DATE',
      value: 'departDate',
    },
    {
      text: 'SYSTEM_DEPARTURE_COUNTRY',
      value: 'departureCountry',
    },
    {
      text: '',
      value: 'direction',
      sortable: false
    },
    {
      text: 'SYSTEM_ARRIVAL_DATE',
      value: 'arrivalDate',
    },
    {
      text: 'SYSTEM_ARRIVAL_COUNTRY',
      value: 'arrivalCountry',
    },
    {
      text: 'SYSTEM_QUANTITY',
      value: 'quantity'
    },
  ];

  @Emit('fetchData')
  emitFetchData() {
    return;
  }

  @Emit('paginationChanged')
  paginationChanged(pagination: IDataTablePagination) {
    return pagination;
  }

  @Emit('sort')
  sort(value: { sortBy: string[], sortDesc: string[] }) {
    this.params.sortBy = value.sortBy[0];
    this.params.sortDirection = value.sortDesc[0] ? 'DESC' : 'ASC';
    return this.params;
  }

}
