import axios from 'axios';
import IStockInternationalMovementFilters from '@/types/IStockInternationalMovementFilters';

export default class StockInternationalMovementRepository {
  static getAll(filters: IStockInternationalMovementFilters) {
    return axios.post(`/stock/international-movements/all`, filters);
  }

  static download(filters: IStockInternationalMovementFilters) {
    return axios.post(`/stock/international-movements/export`, filters, {responseType: 'blob'});
  }

  static getFilters() {
    return axios.get(`/stock/international-movements/forms`);
  }
}
