





































































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import DebounceInput from '../DebounceInput.vue';
import DatePicker from '@/components/DatePicker.vue';
import CollapseMultiSelect from '@/components/CollapseMultiSelect.vue';
import CollapseCombobox from '@/components/CollapseCombobox.vue';
import {minNumber} from '@/utils/validationRules';
import IStockInternationalMovementFilters from '@/types/IStockInternationalMovementFilters';
import {resolveError} from '@/utils/notifications';
import StockInternationalMovementService from '@/services/StockInternationalMovementService';

@Component({
  name: 'Filters',
  components: {
    CollapseMultiSelect,
    DatePicker,
    DebounceInput,
    CollapseCombobox
  },
})
export default class Filters extends Vue {
  @Prop()
  filters!: IStockInternationalMovementFilters;

  @Prop()
  arrivalCountryList!: string[];

  @Prop()
  departureCountryList!: string[];

  @Prop()
  skuList!: string[];

  @Prop()
  loading!: boolean;

  loadingExcel: boolean = false;

  rules = {
    quantityFrom: [],
    quantityTo: [this.minNumber],
  };

  isValid: boolean = true;

  async downloadFile() {
    try {
      this.loadingExcel = true;
      await StockInternationalMovementService.downloadFile(this.filters);
    } catch (e) {
      resolveError(e, 'download');
    } finally {
      this.loadingExcel = false;
    }
  }

  applyFilters() {
    this.$emit('applyFilters');
  }

  minNumber(value: number | string) {
    return minNumber(value, Number(this.filters.quantityFrom));
  }

  @Emit('changed')
  changed() {
    // @ts-ignore
    this.isValid = this.$refs.form.validate();
    return this.filters;
  }
}
